// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ui/loader.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ui/loader.tsx");
  import.meta.hot.lastModified = "1726716723003.0098";
}
// REMIX HMR END

export default function Loader({
  width = 'w-5',
  height = 'h-5'
}) {
  return <span className={`relative loader before:box-border before:absolute before:inset-0 before:rounded-full before:border-2 before:border-solid before:border-primary-500 before:content-[''] after:box-border after:absolute after:rounded-full after:border-2 after:border-solid after:content-[''] after:inset-[1px] after:border-primary-300 ${width} ${height}`}></span>;
}
_c = Loader;
var _c;
$RefreshReg$(_c, "Loader");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;